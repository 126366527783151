import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    do: 0,
    kedalaman: 0,
    konduktifitas: 0,
    tds: 0,
    resistifitas: 0,
    salinitas: 0,
    swsg: 0,
    ph: 0,
    orp: 0,
    suhu: 0,
    amonia: 0,
    nitrat: 0,
    turbidity: 0,
    tss: 0,
    cod: 0,
    bod: 0,
    updated_at: ''
}

export const sensorReducer = createSlice({
    name: 'sensor',
    initialState,
    reducers: {
        setSensor: (state, action) => {
            state.do = action.payload.do;
            state.kedalaman = action.payload.kedalaman;
            state.konduktifitas = action.payload.konduktifitas;
            state.tds = action.payload.tds;
            state.resistifitas = action.payload.resistifitas;
            state.salinitas = action.payload.salinitas;
            state.swsg = action.payload.swsg;
            state.ph = action.payload.ph;
            state.orp = action.payload.orp;
            state.suhu = action.payload.suhu;
            state.amonia = action.payload.amonia;
            state.nitrat = action.payload.nitrat;
            state.turbidity = action.payload.turbidity;
            state.tss = action.payload.tss;
            state.cod = action.payload.cod;
            state.bod = action.payload.bod;
            state.updated_at = action.payload.updated_at; 
        }
    }
});

export const { setSensor } = sensorReducer.actions;
export default sensorReducer.reducer;