import { Button, Card, Carousel, Col, Row, Space, Tag, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSensor } from "../reducers/SensorReducer";
import ReactSpeedometer from "react-d3-speedometer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHomeAlt, faInfoCircle, faMagnifyingGlassLocation, faMapLocation, faSave } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useEffect, useState } from "react";
import paket from '../../package.json';
import dayjs from "dayjs";

function DashboardView(){

    const template = useSelector(state => state.template);
    const sensor = useSelector((state) => state.sensor);
    const stcolor = '#6ad72d';
    const ndcolor = '#ff0000';
    
    return(
        <>
            <center><b>PEMANTAUAN KUALITAS AIR (ONLINE MONITORING)</b></center>
            <h2><center>{template.title}</center></h2>
            <Card size="small">
               <FontAwesomeIcon icon={faHomeAlt} /> {template.alamat}<br /> {/*<div style={{fontSize: 12}}> <FontAwesomeIcon icon={faMapLocation} /> {latitude} : {longitude} /div>*/}
            </Card>
            <Row>
                <Col span={0} sm={0} md={0} lg={24} >
                    
                    <Row justify="space-around" align="middle">

                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['do']} currentValueText={'DO : ' + sensor['do'] + " mg/L"} minValue={0} maxValue={50} />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['kedalaman'] } currentValueText={ 'Kedalaman : ' + sensor['kedalaman'] + " m" } minValue={0}  maxValue={100} />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['konduktifitas'] } currentValueText={ 'Konduktifitas : ' + sensor['konduktifitas'] + " mS/cm" } minValue={0}  maxValue={200} />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['tds']} currentValueText={'TDS : ' + sensor['tds'] + ' mg/L'} minValue={0} maxValue={100000} />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['resistifitas'] } currentValueText={ 'Resistifitas : ' + sensor['resistifitas'] + " Ωcm" } minValue={0}  maxValue={100} />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['salinitas']} currentValueText={'Salinitas : ' + sensor['salinitas'] + ' PSU'} minValue={0} maxValue={70}  />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['swsg'] } currentValueText={ 'SwSg : ' + sensor['swsg'] + ' σt'} minValue={0} maxValue={50}   />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['ph']} currentValueText={'PH : ' + sensor['ph'] + ' pH'} minValue={0} maxValue={14} />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['orp']} currentValueText={'ORP : ' + sensor['orp'] + " mv"} minValue={0} maxValue={2000}  />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['suhu'] } currentValueText={ 'Suhu : ' + sensor['suhu'] + " ºC"} minValue={0} maxValue={50} />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['amonia'] } currentValueText={ 'Amonia : ' + sensor['amonia'] + ' mg/L' } minValue={0} maxValue={9000}  />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['nitrat']} currentValueText={'Nitrat : ' + sensor['nitrat'] + " mg/L"} minValue={0} maxValue={30000} />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['turbidity'] } currentValueText={ 'Turbidity : ' + sensor['turbidity'] + ' NTU'} minValue={0} maxValue={3000}  />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['tss'] } currentValueText={ 'TSS : ' + sensor['tss'] + " mg/l"}  minValue={0} maxValue={500}   />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['cod']} currentValueText={'COD : ' + sensor['cod'] + " mg/l"}  minValue={0} maxValue={500}   />
                        </Col>
                        <Col span={4}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} vlabelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['bod']} currentValueText={'BOD : ' + sensor['bod'] + ' mg/l'} minValue={0} maxValue={60}   />
                        </Col>
                    </Row>
                    
                </Col>
                <Col span={0} sm={0} md={24} lg={0} >
                
                    <Carousel autoplay autoplaySpeed={5000}>
                        <div>
                            <Row justify="space-around" align="middle">
                                <Col span={6}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['do']} currentValueText={'DO : ' + sensor['do'] + " mg/L"} minValue={0} maxValue={50} />
                                </Col>
                                <Col span={6}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['kedalaman'] } currentValueText={ 'Kedalaman : ' + sensor['kedalaman'] + " m" } minValue={0}  maxValue={100} />
                                </Col>
                                <Col span={6}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['konduktifitas'] } currentValueText={ 'Konduktifitas : ' + sensor['konduktifitas'] + " mS/cm" } minValue={0}  maxValue={200} />
                                </Col>
                                <Col span={6}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['tds']} currentValueText={'TDS : ' + sensor['tds'] + ' mg/L'} minValue={0} maxValue={100000} />
                                </Col>
                            </Row>
                            <Row justify="space-around" align="middle">
                                <Col span={6}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['resistifitas'] } currentValueText={ 'Resistifitas : ' + sensor['resistifitas'] + " Ωcm" } minValue={0}  maxValue={100} />
                                </Col>
                                <Col span={6}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['salinitas']} currentValueText={'Salinitas : ' + sensor['salinitas'] + ' PSU'} minValue={0} maxValue={70}  />
                                </Col>
                                <Col span={6}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['swsg'] } currentValueText={ 'SwSg : ' + sensor['swsg'] + ' σt'} minValue={0} maxValue={50}   />
                                </Col>
                                <Col span={6}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['ph']} currentValueText={'PH : ' + sensor['ph'] + ' pH'} minValue={0} maxValue={14} />
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row justify="space-around" align="middle">
                                <Col span={8}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['orp']} currentValueText={'ORP : ' + sensor['orp'] + " mv"} minValue={0} maxValue={2000}  />
                                </Col>
                                <Col span={8}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['suhu'] } currentValueText={ 'Suhu : ' + sensor['suhu'] + " ºC"} minValue={0} maxValue={50} />
                                </Col>
                                <Col span={8}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['amonia'] } currentValueText={ 'Amonia : ' + sensor['amonia'] + ' mg/L' } minValue={0} maxValue={9000}  />
                                </Col>
                                
                            </Row>
                            <Row justify="space-around" align="middle">
                                <Col span={12}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['nitrat']} currentValueText={'Nitrat : ' + sensor['nitrat'] + " mg/L"} minValue={0} maxValue={30000} />
                                </Col>
                                <Col span={12}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['turbidity'] } currentValueText={ 'Turbidity : ' + sensor['turbidity'] + ' NTU'} minValue={0} maxValue={3000}  />
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row justify="space-around" align="middle">
                                <Col span={8}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['tss'] } currentValueText={ 'TSS : ' + sensor['tss'] + " mg/l"} minValue={0} maxValue={500}   />
                                </Col>
                                <Col span={8}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['cod']} currentValueText={'COD : ' + sensor['cod'] + " mg/l"}  minValue={0} maxValue={500}   />
                                </Col>
                                <Col span={8}>
                                    <ReactSpeedometer startColor={stcolor} endColor={ndcolor} vlabelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['bod']} currentValueText={'BOD : ' + sensor['bod'] + ' mg/l'}  minValue={0} maxValue={60}   />
                                </Col>
                            </Row>
                        </div>
                    </Carousel>

                </Col>
                <Col span={24} sm={24} md={0} lg={0} >
                
                    <Row justify="space-around" align="middle">
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['do']} currentValueText={'DO : ' + sensor['do'] + " mg/L"} minValue={0} maxValue={50} />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['kedalaman'] } currentValueText={ 'Kedalaman : ' + sensor['kedalaman'] + " m" } minValue={0}  maxValue={100} />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['konduktifitas'] } currentValueText={ 'Konduktifitas : ' + sensor['konduktifitas'] + " mS/cm" } minValue={0}  maxValue={200} />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['tds']} currentValueText={'TDS : ' + sensor['tds'] + ' mg/L'} minValue={0} maxValue={100000} />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['resistifitas'] } currentValueText={ 'Resistifitas : ' + sensor['resistifitas'] + " Ωcm" } minValue={0}  maxValue={100} />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['salinitas']} currentValueText={'Salinitas : ' + sensor['salinitas'] + ' PSU'} minValue={0} maxValue={70}  />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['swsg'] } currentValueText={ 'SwSg : ' + sensor['swsg'] + ' σt'} minValue={0} maxValue={50}   />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['ph']} currentValueText={'PH : ' + sensor['ph'] + ' pH'} minValue={0} maxValue={14} />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['orp']} currentValueText={'ORP : ' + sensor['orp'] + " mv"} minValue={0} maxValue={2000}  />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['suhu'] } currentValueText={ 'Suhu : ' + sensor['suhu'] + " ºC"} minValue={0} maxValue={50} />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['amonia'] } currentValueText={ 'Amonia : ' + sensor['amonia'] + ' mg/L' } minValue={0} maxValue={9000}  />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['nitrat']} currentValueText={'Nitrat : ' + sensor['nitrat'] + " mg/L"} minValue={0} maxValue={30000} />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['turbidity'] } currentValueText={ 'Turbidity : ' + sensor['turbidity'] + ' NTU'} minValue={0} maxValue={3000}  />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={ sensor['tss'] } currentValueText={ 'TSS : ' + sensor['tss'] + " mg/l"}  minValue={0} maxValue={500}  />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} labelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['cod']} currentValueText={'COD : ' + sensor['cod'] + " mg/l"}  minValue={0} maxValue={500}  />
                        </Col>
                        <Col span={12}>
                            <ReactSpeedometer startColor={stcolor} endColor={ndcolor} vlabelFontSize={8} valueTextFontSize={12} height={150} width={180} value={sensor['bod']} currentValueText={'BOD : ' + sensor['bod'] + ' mg/l'} minValue={0} maxValue={60}   />
                        </Col>
                    </Row>

                </Col>
            </Row>
            <div style={{textAlign: 'end'}}>
                <Tag>Tercatat : { dayjs(sensor['updated_at']).format('YYYY-MM-DD HH:mm:ss')}</Tag>
            </div>

            <br /><br /><br />
        </>
    );
}

export default DashboardView;