import { Button, Col, DatePicker, Row, Space, Table, Form, Radio } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { NumberFormatBase } from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faSortAlphaAsc, faSortAlphaDesc } from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";
import paket from '../../package.json';
import { useSelector } from "react-redux";

function ReportView(){

    const user = useSelector(state => state.session.user);
    const [data, setData] = useState([]);
    const kolom = [
        {title: 'Tercatat', render:  e=> dayjs(e['created_at']).format('YYYY-MM-DD HH:mm:ss')},
        {title: 'DO (mg/L)', render: e=> <NumberFormatBase displayType="text" value={e['do']} />  },
        {title: 'Kedalaman (m)', render: e=> <NumberFormatBase displayType="text" value={e['kedalaman']} />  },
        {title: 'Konduktifitas (mS/cm)', render: e=> <NumberFormatBase displayType="text" value={e['konduktifitas']} />  },
        {title: 'TDS (mg/L)', render: e=> <NumberFormatBase displayType="text" value={e['tds']} />  },
        {title: 'Resistifitas (Ωcm)', render: e=> <NumberFormatBase displayType="text" value={e['resistifitas']} />  },
        {title: 'Salinitas (PSU)', render: e=> <NumberFormatBase displayType="text" value={e['salinitas']} />  },
        {title: 'SwSg (σt)', render: e=> <NumberFormatBase displayType="text" value={e['swsg']} />  },
        {title: 'pH (pH)', render: e=> <NumberFormatBase displayType="text" value={e['ph']} />  },
    ]

    const kolom2 = [
        {title: 'ORP (mv)', render: e=> <NumberFormatBase displayType="text" value={e['orp']} />  },
        {title: 'Suhu (ºC)', render: e=> <NumberFormatBase displayType="text" value={e['suhu']} />  },
        {title: 'Amonia (mg/L)', render: e=> <NumberFormatBase displayType="text" value={e['amonia']} />  },
        {title: 'Nitrat (mg/L)', render: e=> <NumberFormatBase displayType="text" value={e['nitrat']} />  },
        {title: 'Turbidity (NTU)', render: e=> <NumberFormatBase displayType="text" value={e['turbidity']} />  },
        {title: 'TSS (mg/l)', render: e=> <NumberFormatBase displayType="text" value={e['tss']} />  },
        {title: 'COD (mg/l)', render: e=> <NumberFormatBase displayType="text" value={e['cod']} />  },
        {title: 'BOD (mg/l)', render: e=> <NumberFormatBase displayType="text" value={e['bod']} />  },
        
    ]

    const kolom3 = [
        {title: 'Tercatat', render:  e=> dayjs(e['created_at']).format('YYYY-MM-DD HH:mm:ss')},
        {title: 'DO (mg/L)', render: e=> <NumberFormatBase displayType="text" value={e['do']} />  },
        {title: 'Kedalaman (m)', render: e=> <NumberFormatBase displayType="text" value={e['kedalaman']} />  },
        {title: 'Konduktifitas (mS/cm)', render: e=> <NumberFormatBase displayType="text" value={e['konduktifitas']} />  },
        {title: 'TDS (mg/L)', render: e=> <NumberFormatBase displayType="text" value={e['tds']} />  },
        {title: 'Resistifitas (Ωcm)', render: e=> <NumberFormatBase displayType="text" value={e['resistifitas']} />  },
        {title: 'Salinitas (PSU)', render: e=> <NumberFormatBase displayType="text" value={e['salinitas']} />  },
        {title: 'SwSg (σt)', render: e=> <NumberFormatBase displayType="text" value={e['swsg']} />  },
        {title: 'pH (pH)', render: e=> <NumberFormatBase displayType="text" value={e['ph']} />  },
        {title: 'ORP (mv)', render: e=> <NumberFormatBase displayType="text" value={e['orp']} />  },
        {title: 'Suhu (ºC)', render: e=> <NumberFormatBase displayType="text" value={e['suhu']} />  },
        {title: 'Amonia (mg/L)', render: e=> <NumberFormatBase displayType="text" value={e['amonia']} />  },
        {title: 'Nitrat (mg/L)', render: e=> <NumberFormatBase displayType="text" value={e['nitrat']} />  },
        {title: 'Turbidity (NTU)', render: e=> <NumberFormatBase displayType="text" value={e['turbidity']} />  },
        {title: 'TSS (mg/l)', render: e=> <NumberFormatBase displayType="text" value={e['tss']} />  },
        {title: 'COD (mg/l)', render: e=> <NumberFormatBase displayType="text" value={e['cod']} />  },
        {title: 'BOD (mg/l)', render: e=> <NumberFormatBase displayType="text" value={e['bod']} />  },
    ]

    const [loading, setLoading] = useState(true);
    const [awal, setAwal] = useState(dayjs());
    const [akhir, setAkhir] = useState(dayjs());
    const [sort, setSort] = useState('desc');

    const ekport = () => {
        let _awal = awal.format('YYYY-MM-DD');
        let _akhir = akhir.format('YYYY-MM-DD');
        let url = paket.app_url+'/sensor/export?awal='+_awal+'&akhir='+_akhir+'&sort='+sort;
        FileSaver.saveAs(url);
    }

    const fill = () => {
        let _awal = awal.format('YYYY-MM-DD');
        let _akhir = akhir.format('YYYY-MM-DD');
        
        setLoading(true);
        setData([]);
        axios.get(paket.app_url+'/sensor?awal='+_awal+'&akhir='+_akhir+'&sort='+sort).then(res=>{
            let json = res.data;
            for(let a=0; a<json.length; a++){
                json[a]['key'] = a+1;
            }
            setData(json);
            setLoading(false);
        });
    }

    useEffect(()=>{
        fill();
    }, []);

    useEffect(fill, [awal, akhir, sort]);

    return(
        <Space direction="vertical" style={{width: '100%'}}>
            <Form layout="inline">
                <Form.Item label='Tanggal Awal'>
                    <DatePicker style={{width: '100%'}} value={awal} allowClear={false} onChange={e=> e!==null ? setAwal(e) : null } />
                </Form.Item>
                <Form.Item label='Tanggal Akhir'>
                    <DatePicker style={{width: '100%'}} value={akhir} allowClear={false} onChange={e=> e!==null ? setAkhir(e) : null } />
                </Form.Item>
                <Form.Item>
                    <Radio.Group value={sort} onChange={e=> setSort(e.target.value)}>
                        <Radio value='asc'><FontAwesomeIcon icon={faSortAlphaAsc} /> </Radio> 
                        <Radio value='desc'><FontAwesomeIcon icon={faSortAlphaDesc} /> </Radio> 
                    </Radio.Group>
                </Form.Item>
                <Form.Item>
                    {user.id !== undefined ?
                    <Button onClick={ekport} type="primary" icon={<FontAwesomeIcon icon={faFileExcel} />}>Eksport</Button>
                    : null }
                </Form.Item>
            </Form>
            <Row>
                <Col md={24} lg={0}>
                    <Table loading={loading}
                    expandable={{
                        expandedRowRender : (render) => <Table size="small" bordered pagination={false} dataSource={[render]} columns={ kolom2 } />
                    }}
                    size="small" pagination={false} bordered dataSource={data} columns={kolom} />
                </Col>
                <Col md={0} lg={24}>
                    <Table pagination={false} size="small" loading={loading} bordered dataSource={data} columns={kolom3} />
                </Col>
            </Row>
            <br /><br /><br />
        </Space>
    );
}

export default ReportView;