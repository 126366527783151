import { Button, Col, Menu, Row, Skeleton, Space, Spin, Tag, message } from "antd";
import { Layout, Flex } from 'antd';
import paket from '../package.json';
import { NavLink, Route, Routes } from "react-router-dom";
import DashboardView from "./views/DashboardView";
import SettingView from "./views/SettingView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromWaterPump, faBatteryEmpty, faCalendar, faCalendarCheck, faCheckCircle, faCogs, faGasPump, faKey, faPumpMedical, faPumpSoap, faSignInAlt, faSignOut, faSigning, faTable, faTachometerAlt, faTemperatureEmpty, faTemperatureFull, faTemperatureHalf, faTimeline, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setSetting } from "./reducers/SettingReducer";
import ScheduleView from "./views/ScheduleView";
import ReportView from "./views/ReportView";
import UserLoginView from "./views/UserLoginView";
import { sessionService } from "redux-react-session";
import UserLogoutView from "./views/UserLogoutView";
import UpdateView from "./views/UpdateView";
import { setSensor } from "./reducers/SensorReducer";
import dayjs from "dayjs";
import { setTemplate } from "./reducers/TemplateReducer";
import UserPasswordView from "./views/UserPasswordView";

const { Header, Footer, Sider, Content } = Layout;

function App(){

    const dispatch = useDispatch();
    const user = useSelector(state => state.session.user);
    const versi = useSelector(state => state.setting.versi);
    const sensor = useSelector(state => state.sensor);
    const setting = useSelector(state => state.setting);

    useEffect(()=>{

        sessionService.loadUser().then(e=>{
            if(e!=undefined){
                sessionService.saveUser(e);
            }
        }).catch(()=>{
            sessionService.deleteUser();
        });

        setInterval(() => {
            axios.get(paket.app_url+'/device').then(res=>{
                let json = res.data;
                json.ready = true;
                json.otomatis = JSON.parse(json.otomatis);
                json.sumber_sungai = JSON.parse(json.sumber_sungai);
                json.sumber_bak = JSON.parse(json.sumber_bak);
                json.pembuangan_bak = JSON.parse(json.pembuangan_bak);
                json.pembuangan_sungai = JSON.parse(json.pembuangan_sungai);
                json.pompa = JSON.parse(json.pompa);
                json.penggantian_air = JSON.parse(json.penggantian_air);
                json.lama_penggantian = JSON.parse(json.lama_penggantian);
                json.kirim = JSON.parse(json.kirim);
                json.versi = json.versi;
                //console.log(json);
                dispatch(setSetting(json));
            }).catch(er=>message.error(er.message));

            axios.get(paket.app_url+'/device/realtime').then(res=>{
                let json = res.data;
                dispatch(setSensor(json));
            }).catch(er=>message.error(er.message));
        }, 2000);

        axios.get(paket.app_url+'/template').then(res=>{
            dispatch(setTemplate(res.data));
        }).catch(er=>message.error(er.message));


    }, []);

    return(
        versi==='' ? <center><br /><br /><br /><Spin /> Loading...</center> :
        versi!==paket.version ? <UpdateView /> :
        <Layout style={{margin: -8, height: '100vh'}}>
            <Header style={{backgroundColor: paket.app_primary}}>
                <Row>
                    <Col flex='auto' style={{color: 'white'}}><b>{paket.app_name}</b> <small>{paket.version}</small></Col>
                    <Col>
                        {user.id===undefined ?
                        <Space>
                            <NavLink to='/'><Button size="small" type="primary" icon={<FontAwesomeIcon icon={faTachometerAlt} />}>Dashboard</Button></NavLink>
                            <NavLink to='/report'><Button size="small" type="primary" icon={<FontAwesomeIcon icon={faTable} />}>Laporan</Button></NavLink>
                            <NavLink to='/user/login'><Button size="small" type="primary" icon={<FontAwesomeIcon icon={faSignInAlt} />}>Login</Button></NavLink>
                        </Space>
                        :
                        <Space>
                            <NavLink to='/'><Button size="small" type="primary" icon={<FontAwesomeIcon icon={faTachometerAlt} />}>Dashboard</Button></NavLink>
                            <NavLink to='/report'><Button size="small" type="primary" icon={<FontAwesomeIcon icon={faTable} />}>Laporan</Button></NavLink>
                            <NavLink to='/setting'><Button size="small" type="primary" icon={<FontAwesomeIcon icon={faCogs} />}>Pengaturan</Button></NavLink>
                            <NavLink to='/schedule'><Button size="small" type="primary" icon={<FontAwesomeIcon icon={faCalendarCheck} />}>Otomatis</Button></NavLink>
                            <NavLink to='/user/password'><Button size="small" type="primary" icon={<FontAwesomeIcon icon={faKey} />}></Button></NavLink>
                            <NavLink to='/user/logout'><Button size="small" type="primary" icon={<FontAwesomeIcon icon={faSignOut} />}>Logout</Button></NavLink>
                        </Space>
                        }
                    </Col>
                </Row>
            </Header>
            <Content style={{padding: 10, overflow: 'auto'}}>
                {user.id===undefined ?
                <Routes>
                    <Route path="*" element={<DashboardView />} />
                    <Route path="/report" element={<ReportView />} />
                    <Route path="/user/login" element={<UserLoginView />} />
                </Routes>
                :
                <Routes>
                    <Route path="*" element={<DashboardView />} />
                    <Route path="/report" element={<ReportView />} />
                    <Route path="/setting" element={<SettingView />} />
                    <Route path="/schedule" element={<ScheduleView />} />
                    <Route path="/user/password" element={<UserPasswordView />} />
                    <Route path="/user/logout" element={<UserLogoutView />} />
                </Routes>
                }
            </Content>
            <Footer>

                {dayjs(sensor.updated_at).diff(dayjs(), 'minutes') < 0 ? <Tag color='red'><FontAwesomeIcon icon={faTimesCircle} /> Offline</Tag> : <Tag color="green"><FontAwesomeIcon icon={faCheckCircle} /> Online</Tag> }
                
                {setting.pompa ===true ? <Tag color="lime"><FontAwesomeIcon icon={faArrowUpFromWaterPump} /> Pompa On</Tag> : <Tag color="orange"><FontAwesomeIcon icon={faArrowUpFromWaterPump} /> Pompa Off</Tag> }

            </Footer>
        </Layout>
    );
}

export default App;